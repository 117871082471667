import React from 'react'

const MoreIcon = ({ height = 20, top = 0, className = {}}) => {
  return (
    <svg className={className} width="6" height="19" viewBox="0 0 6 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 16.2362C0 14.7098 1.34315 13.4724 3 13.4724C4.65685 13.4724 6 14.7098 6 16.2362C6 17.7626 4.65685 19 3 19C1.34315 19 0 17.7626 0 16.2362Z" fill="#7C6C6C"/>
      <path d="M0 9.50005C0 7.97363 1.34315 6.73624 3 6.73624C4.65685 6.73624 6 7.97363 6 9.50005C6 11.0265 4.65685 12.2639 3 12.2639C1.34315 12.2639 0 11.0265 0 9.50005Z" fill="#7C6C6C"/>
      <path d="M0 2.76378C0 1.23737 1.34315 -3.19481e-05 3 -3.19481e-05C4.65685 -3.19481e-05 6 1.23737 6 2.76378C6 4.29019 4.65685 5.52759 3 5.52759C1.34315 5.52759 0 4.29019 0 2.76378Z" fill="#7C6C6C"/>
    </svg>
  )
}

export default MoreIcon
