import React from 'react'

const ListUnorderedIcon = ({ height = 20, top = 0, style, className }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height={height} style={{ marginTop: top, ...style }} className={className} fill="currentColor" class="bi bi-list-ul" viewBox="0 0 16 16">
      <path fillRule="evenodd" d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm-3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
    </svg>
  )
}

export default ListUnorderedIcon